import { BookingsQueryParams } from '@wix/bookings-adapter-ooi-wix-sdk';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { createWixSdkAdapter } from '../../../utils/sdkAdapterFactory';
import { getSessionValues } from '../../../utils/storageFunctions';
import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';
import { DynamicPriceInfo } from '@wix/bookings-uou-types';

export const getSessionStorageAdapter = ({
  flowAPI,
}: {
  flowAPI: ControllerFlowAPI;
}) => {
  const wixSdkAdapter = createWixSdkAdapter(
    flowAPI.controllerConfig,
    flowAPI.experiments,
  );

  const SessionStorageAdapter = {
    getServiceId(): string | undefined {
      const serviceId = getSessionValues(
        wixSdkAdapter,
        BookingsQueryParams.SERVICE_ID,
      );
      return serviceId;
    },
    getSlotAvailability(): SlotAvailability | undefined {
      const slotAvailability = getSessionValues(
        wixSdkAdapter,
        BookingsQueryParams.AVAILABILITY_SLOT,
      );
      return slotAvailability;
    },
    getTimezone(): string | undefined {
      const timezone = getSessionValues(
        wixSdkAdapter,
        BookingsQueryParams.TIMEZONE,
      );
      return timezone;
    },
    getIsEcom(): boolean | undefined {
      const isEcom = getSessionValues(wixSdkAdapter, BookingsQueryParams.ECOM);
      return isEcom;
    },
    getDynamicPricePreSelection(): DynamicPriceInfo | undefined {
      const dynamicPriceInfoStorage: DynamicPriceInfo | undefined =
        getSessionValues(wixSdkAdapter, BookingsQueryParams.DYNAMIC_PRICING);
      return dynamicPriceInfoStorage;
    },
  };

  return SessionStorageAdapter;
};
