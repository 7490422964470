import { DynamicPriceInfo } from '@wix/bookings-uou-types';
import { CreateActionParams } from '../actions';
import { FormNestedSlot } from '../../../../types/formSelectedSlots';
import { FormStatus } from '../../../../types/form-state';
import { getServiceSlotIdentifier } from '../../../../utils';

export type AddAdditionalSlot = (
  additionalFormSlots: AdditionalFormSlots,
) => Promise<void>;

export type AdditionalFormSlots = {
  formNestedSlot: FormNestedSlot;
  dynamicPrice?: DynamicPriceInfo;
}[];

export function createAddAdditionalSlotAction({
  getControllerState,
}: CreateActionParams): AddAdditionalSlot {
  return async (additionalFormSlots) => {
    const [state, setState] = getControllerState();
    let updatedNestedSlots;
    let updatedDynamicPricePreSelection;

    if (state.formPageData) {
      updatedNestedSlots = [...state.formPageData.formSelectedSlot.nestedSlots];
      updatedDynamicPricePreSelection = {
        ...state.formPageData.dynamicPricePreSelection,
      };

      for (const additionalSlot of additionalFormSlots) {
        const { formNestedSlot, dynamicPrice } = additionalSlot;
        updatedNestedSlots.push(formNestedSlot);

        if (dynamicPrice) {
          updatedDynamicPricePreSelection[
            getServiceSlotIdentifier(formNestedSlot)
          ] = dynamicPrice;
        }
      }

      setState({
        formPageData: {
          ...state.formPageData,
          formSelectedSlot: {
            ...state.formPageData.formSelectedSlot,
            nestedSlots: updatedNestedSlots,
          },
          dynamicPricePreSelection: updatedDynamicPricePreSelection,
        },
        status: FormStatus.REINITIALIZING,
      });
    }
  };
}
