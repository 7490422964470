import {
  BookedSchedule,
  Booking,
  BookingSourceActor,
  BookingSourcePlatform,
  ContactDetails,
  CreateBookingRequest,
  CustomFormField,
  ParticipantChoices,
  SelectedPaymentOption,
} from '../../../types/ambassador/bookings/ambassador-bookings-v2-booking';
import { FormNestedSlot } from '../../../types/formSelectedSlots';
import { Service } from '../../../utils/mappers/service.mapper';
import { Membership } from '@wix/ambassador-totals-calculator/http';
import { ServiceType } from '@wix/bookings-uou-types';
import { IHttpClient } from '@wix/yoshi-flow-editor';
import { createBooking } from '@wix/ambassador-bookings-v2-booking/http';
import { mapFormNestedSlotToServerSlot } from '../../../utils/mappers/ambassador-bookings-v2.mapper';

export const createSingleSlotBookings = async (
  httpClient: IHttpClient,
  service: Service,
  slot: FormNestedSlot,
  contactDetails: ContactDetails,
  additionalFields: CustomFormField[],
  numberOfParticipants: number | undefined,
  participantsChoices: ParticipantChoices | undefined,
  totalParticipants: number | undefined,
  sendSmsReminder: boolean | undefined,
  selectedMembership: Membership | undefined,
  selectedPaymentType: SelectedPaymentOption,
) => {
  const isCourse = service.type === ServiceType.COURSE;
  const booking: Booking = {
    numberOfParticipants,
    participantsChoices,
    totalParticipants,
    bookingSource: {
      actor: BookingSourceActor.CUSTOMER,
      platform: BookingSourcePlatform.WEB,
    },
    contactDetails,
    additionalFields,
    selectedPaymentOption: selectedMembership
      ? SelectedPaymentOption.MEMBERSHIP
      : selectedPaymentType,
    bookedEntity: {
      ...(isCourse
        ? {
            schedule: mapBookedSchedule({ service, slot }),
          }
        : {
            slot: mapFormNestedSlotToServerSlot(slot),
          }),
    },
  };

  const createBookingRequest: CreateBookingRequest = {
    booking,
    sendSmsReminder,
    participantNotification: {
      notifyParticipants: true,
    },
  };
  const result = await httpClient.request(createBooking(createBookingRequest));
  return result.data;
};

const mapBookedSchedule = ({
  service,
  slot,
}: {
  service: Service;
  slot: FormNestedSlot;
}): BookedSchedule => {
  return {
    serviceId: service.id,
    scheduleId: service.scheduleId,
    timezone: slot.timezone,
  };
};
