import {
  ContactDetails,
  CustomFormField,
} from '../../../types/ambassador/bookings/ambassador-bookings-v2-booking';
import { getServiceSlotIdentifier, mapToArray } from '../../../utils';
import { mapDynamicParticipantsNumber } from '../../../utils/mappers/form-submission.mapper';
import { ServiceData, SlotService } from '../../../utils/state/types';
import { FormApiContext } from '../utils';
import { createGroupSlotBookings } from './createGroupSlotBookings';
import { createSingleSlotBookings } from './createSingleSlotBookings';

export type CreateBookingsArgs = {
  serviceData: ServiceData;
  contactDetails: ContactDetails;
  additionalFields: CustomFormField[];
  numberOfParticipants: number | undefined;
  sendSmsReminder: boolean | undefined;
  v2Availability?: boolean;
};

export const createBookings = async ({
  httpClient,
  serviceData,
  contactDetails,
  additionalFields,
  numberOfParticipants,
  sendSmsReminder,
}: CreateBookingsArgs & FormApiContext): Promise<{
  [key: string]: string;
}> => {
  if (serviceData.isSingleService) {
    const slotService = mapToArray<SlotService>(serviceData.slotServices)[0];
    const selectedMembership =
      slotService.memberships?.eligibleMemberships?.find(
        (membership) => membership?.id === slotService.selectedPaymentOption.id,
      );
    const { participantsChoices, totalParticipants } =
      mapDynamicParticipantsNumber(
        slotService.dynamicPriceInfo?.selectedVariants,
      );
    const createBookingResponse = await createSingleSlotBookings(
      httpClient,
      slotService.service,
      slotService.nestedSlot,
      contactDetails,
      additionalFields,
      numberOfParticipants,
      participantsChoices,
      totalParticipants,
      sendSmsReminder,
      selectedMembership,
      slotService.selectedPaymentType,
    );
    return createBookingResponse?.booking?.id
      ? {
          [getServiceSlotIdentifier(slotService.nestedSlot)]:
            createBookingResponse.booking.id,
        }
      : {};
  } else {
    const createGroupBookingsResponse = await createGroupSlotBookings(
      serviceData,
    );
    return createGroupBookingsResponse;
  }
};
