import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  BusinessInfo,
  CartModalStatus,
  FieldLayout,
  PaymentType,
  TFunction,
} from '../../types/types';
import {
  AvailabilityType,
  FormNestedSlot,
  FormSelectedSlot,
} from '../../types/formSelectedSlots';
import { GetActiveFeaturesResponse } from '@wix/ambassador-services-catalog-server/types';
import {
  OfferedAsType,
  PaymentType as UoUPaymentType,
  ServiceLocationType,
  ServiceType,
  TimezoneType,
} from '@wix/bookings-uou-types';
import { Service } from '../mappers/service.mapper';
import { FormViewField, ValidationProperties } from '@wix/forms-ui/types';
import { FieldType } from '@wix/ambassador-forms-v2-form/types';
import { BookingRequestKeyMappings } from '../mappers/form-submission.mapper';
import { FormState, SlotService, SlotServices } from '../state/types';
import { mockEditorContext } from '../../../__tests__/mocks/mockEditorContext';
import { getDefaultPaymentOptionId } from '../payment';
import { FormStatus } from '../../types/form-state';
import settingsParams from '../../components/BookingsForm/settingsParams';
import { SelectedPaymentOption } from '../../types/ambassador/bookings/ambassador-bookings-v2-booking';

enum DummyFieldIds {
  FULL_NAME = 'dummy-full-name-field',
  FIRST_NAME = 'dummy-first-name-field',
  LAST_NAME = 'dummy-last-name-field',
  EMAIL = 'dummy-email-field',
  PHONE = 'dummy-phone-field',
  MESSAGE = 'dummy-message-field',
}

export function createDummyState({
  flowApi,
  businessInfo,
  isPricingPlanInstalled,
  isMemberAreaInstalled,
  isBookingsOnEcom,
  isCart = false,
  isMultiServicesAppointmentsEnable = false,
}: {
  flowApi: ControllerFlowAPI;
  businessInfo: BusinessInfo;
  isPricingPlanInstalled: boolean;
  isMemberAreaInstalled: boolean;
  isCart?: boolean;
  isBookingsOnEcom: boolean;
  isMultiServicesAppointmentsEnable?: boolean;
}): FormState {
  const { t } = flowApi.translations;
  const formSelectedSlot = createDummyFormSelectedSlot({
    isMultiServicesAppointmentsEnable,
  });
  const service = createDummyService(
    flowApi,
    businessInfo,
    isPricingPlanInstalled,
  );

  return {
    formSelectedSlot,
    serviceData: {
      form: service.formFromCatalog,
      isSingleService: !isMultiServicesAppointmentsEnable,
      maxNumberOfParticipants: 1,
      summaryPaymentDetails: {
        totalPrice: 10,
      },
      formSchema: {
        fields: [
          createDummyFormField({
            id: DummyFieldIds.FULL_NAME,
            label: t('app.dummy-data.field.full-name'),
            type: FieldType.TEXT,
            layout: FieldLayout.SHORT,
            validationProperties: {
              required: true,
            },
          }),
          createDummyFormField({
            id: DummyFieldIds.EMAIL,
            label: t('app.dummy-data.field.email'),
            type: FieldType.EMAIL,
            layout: FieldLayout.SHORT,
            validationProperties: {
              required: true,
            },
          }),
          createDummyFormField({
            id: DummyFieldIds.PHONE,
            label: t('app.dummy-data.field.phone'),
            type: FieldType.PHONE,
            layout: FieldLayout.SHORT,
          }),
          createDummyFormField({
            id: DummyFieldIds.MESSAGE,
            label: t('app.dummy-data.field.add-message'),
            type: FieldType.PARAGRAPH,
          }),
        ],
      },
      slotServices: generateDummySlotServices(
        formSelectedSlot,
        service,
        businessInfo,
        flowApi,
        isPricingPlanInstalled,
      ),
    },
    businessInfo,
    activeFeatures: createDummyActiveFeatures(),
    memberDetails: undefined,
    errors: [],
    couponInfo: {
      areCouponsAvailable: false,
      isCouponInputDisplayed: false,
    },
    isPricingPlanInstalled,
    isMemberAreaInstalled,
    isCart,
    editorContext: mockEditorContext({
      isDummy: true,
    }),
    status: FormStatus.IDLE,
    formInputs: {
      numberOfParticipants: 1,
    },
    isBookingsOnEcom,
    cartModal: {
      status: CartModalStatus.CLOSED,
      lineItems: [],
    },
    isMultiServicesAppointmentsEnable,
  };
}

function generateDummySlotServices(
  formSelectedSlot: FormSelectedSlot,
  service: Service,
  businessInfo: BusinessInfo,
  flowApi: ControllerFlowAPI,
  isPricingPlanInstalled: boolean,
): SlotServices {
  const slotServices: SlotServices = {};

  formSelectedSlot.nestedSlots.forEach((slot) => {
    slotServices[slot.lineItemId] = {
      service,
      nestedSlot: slot,
      paymentDetails: {
        currency: businessInfo?.currency || 'USD',
        price: 10,
        priceText: '',
        minCharge: 0,
        isFree: false,
        paymentType: SelectedPaymentOption.OFFLINE as unknown as UoUPaymentType,
      },
      selectedPaymentOption: {
        label: 'some-label',
        id: getDefaultPaymentOptionId({
          settings: flowApi.settings,
          servicePayment: service.payment,
          isPricingPlanInstalled,
        }),
        type: PaymentType.SINGLE_SESSION,
      },
      possiblePlans: [],
      selectedPaymentType: flowApi.settings.get(
        settingsParams.defaultPaymentTime,
      ) as unknown as SelectedPaymentOption,
    } as SlotService;
  });

  return slotServices;
}

function createDummyService(
  flowApi: ControllerFlowAPI,
  businessInfo: BusinessInfo,
  isPricingPlanInstalled: boolean,
): Service {
  const { t } = flowApi.translations;
  return {
    name: t('app.dummy-data.service'),
    staffMembers: [
      {
        id: 'dummy-staff-id',
        name: t('app.dummy-data.staff'),
      },
    ],
    // rate: Rate;
    location: {
      id: 'dummy-location-id',
      locationType: ServiceLocationType.OWNER_CUSTOM,
      address: t('app.dummy-data.location'),
    },
    isPendingApprovalFlow: false,
    isWaitingListFlow: false,
    videoConferenceProviderId: 'some-video-provider',
    paymentTypes: [SelectedPaymentOption.OFFLINE, SelectedPaymentOption.ONLINE],
    type: ServiceType.INDIVIDUAL,
    scheduleId: 'dummy-schedule-id',
    formHeader: {
      title: t('app.dummy-data.header.title'),
      description: t('app.dummy-data.header.description'),
      isDescriptionHidden: false,
    },
    payment: {
      offeredAs: [
        OfferedAsType.ONE_TIME,
        ...(isPricingPlanInstalled ? [OfferedAsType.PRICING_PLAN] : []),
      ],
      paymentDetails: {
        currency: businessInfo.currency,
        isFree: false,
        paymentType: SelectedPaymentOption.ONLINE,
      },
      pricingPlanInfo: {
        pricingPlans: [
          {
            id: 'dummy-pricing-plan',
            name: 'Pricing Plan',
          },
        ],
      },
    },
    actionLabels: {
      onlinePaymentLabel: t('app.dummy-data.button'),
    },
  } as unknown as Service;
}

export function createDummySubmission(t: TFunction) {
  return {
    [DummyFieldIds.FULL_NAME]: {
      value: t('app.dummy-data.field.full-name.place-holder'),
    },
    [DummyFieldIds.FIRST_NAME]: {
      value: t('app.dummy-data.field.first-name.place-holder'),
    },
    [DummyFieldIds.LAST_NAME]: {
      value: t('app.dummy-data.field.last-name.place-holder'),
    },
    [DummyFieldIds.PHONE]: {
      value: t('app.dummy-data.field.phone.place-holder'),
    },
    [DummyFieldIds.EMAIL]: {
      value: t('app.dummy-data.field.email.place-holder'),
    },
  };
}

export function createDummyEmptySubmission() {
  return {
    [DummyFieldIds.FIRST_NAME]: { value: '' },
    [DummyFieldIds.LAST_NAME]: { value: '' },
    [DummyFieldIds.EMAIL]: { value: '' },
  };
}

export function createDummyBusinessInfo(flowApi: ControllerFlowAPI) {
  const { config } = flowApi.translations;
  return {
    language: config.language,
    currency: 'USD',
    timeZone: 'Asia/Jerusalem',
    timezoneProperties: {
      defaultTimezone: TimezoneType.BUSINESS,
      clientCanChooseTimezone: true,
    },
    regionalSettingsLocale: 'en-US',
  };
}

function createDummyActiveFeatures(): GetActiveFeaturesResponse {
  return {
    applicableForCourse: true,
    applicableForGroups: true,
    applicableForIndividual: true,
    applicableForPayments: true,
    applicableForReminders: true,
    applicableForServiceList: true,
    applicableForSmsReminders: true,
    bookingsStaffLimit: 100,
  };
}

function generateNestedSlots({
  isMultiServicesAppointmentsEnable = false,
}: {
  isMultiServicesAppointmentsEnable?: boolean;
}): FormNestedSlot[] {
  const nestedSlotsLength = isMultiServicesAppointmentsEnable ? 3 : 1;

  return new Array(nestedSlotsLength).fill('').map((_, i) => ({
    timezone: 'us',
    lineItemId: i.toString(),
    availabilityType:
      nestedSlotsLength > 1
        ? AvailabilityType.GOLDEN
        : AvailabilityType.STANDARD,
    serviceId: 'some-service-id',
    startDate: '54321',
    endDate: '12345',
  }));
}

function createDummyFormSelectedSlot({
  isMultiServicesAppointmentsEnable,
}: {
  isMultiServicesAppointmentsEnable: boolean;
}): FormSelectedSlot {
  return {
    startDate: '54321',
    endDate: '12345',
    timezone: 'us',
    nestedSlots: generateNestedSlots({ isMultiServicesAppointmentsEnable }),
  };
}

function createDummyFormField({
  id,
  label,
  bookingsKey,
  type,
  layout,
  validationProperties,
}: {
  id: string;
  label: string;
  type: FieldType;
  layout?: FieldLayout;
  bookingsKey?: BookingRequestKeyMappings;
  validationProperties?: ValidationProperties;
}): FormViewField {
  return {
    externalId: id,
    renderInfo: {
      type,
      displayProperties: {
        label,
      },
      metadata: {
        ...(bookingsKey ? { bookingsKey } : {}),
        ...(layout
          ? {
              layout: { appearance: layout },
            }
          : {}),
      },
      ...(validationProperties
        ? {
            validationProperties,
          }
        : {}),
    },
  };
}
