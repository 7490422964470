import {
  FormPageData,
  IncompleteFormNestedSlot,
  PageAPIData,
} from '../../types/types';
import { generateLineItemId } from '../../api/implementations/utils';
import {
  AvailabilityType,
  FormNestedSlot,
} from '../../types/formSelectedSlots';
import { DynamicPriceInfo } from '@wix/bookings-uou-types';
import { getServiceSlotIdentifier } from '..';

export const mapSlotAvailabilityToFormSelectedSlot = (
  pageData: PageAPIData,
): FormPageData => {
  let dynamicPricePreSelection: { [key: string]: DynamicPriceInfo } = {};

  const { slotAvailability, timezone } = pageData;
  const { openSpots, totalSpots, slot, isFromV2 } = slotAvailability || {};
  const { endDate, startDate, location } = slot || {};

  const formSelectedSlot = {
    timezone,
    ...(isFromV2 ? { v2Availability: isFromV2 } : {}),
    ...(openSpots ? { openSpots } : {}),
    ...(totalSpots ? { totalSpots } : {}),
    ...(endDate ? { endDate } : {}),
    ...(startDate ? { startDate } : {}),
    ...(location ? { location } : {}),
    nestedSlots: generateNestedSlots(pageData),
  };

  if (Array.isArray(pageData.dynamicPricePreSelection)) {
    dynamicPricePreSelection = pageData.dynamicPricePreSelection.reduce(
      (acc, curr) => {
        // if there is no slotAvailability, we are in a course flow which means that there is no dynamic price so nothing to do here
        if (pageData.slotAvailability) {
          const relevantSlot = formSelectedSlot.nestedSlots.find(
            (nestedSlot) => {
              if (nestedSlot.startDate && nestedSlot.serviceId) {
                return (
                  nestedSlot.startDate === curr.startDate &&
                  nestedSlot.serviceId === curr.serviceId
                );
              }
              return false;
            },
          );
          if (curr.dynamicPriceInfo && relevantSlot) {
            acc[getServiceSlotIdentifier(relevantSlot)] = curr.dynamicPriceInfo;
          }
        }
        return acc;
      },
      dynamicPricePreSelection,
    );
  } else {
    const firstSlot = formSelectedSlot.nestedSlots[0];
    if (pageData.dynamicPricePreSelection && firstSlot) {
      dynamicPricePreSelection = {
        [getServiceSlotIdentifier(firstSlot)]:
          pageData.dynamicPricePreSelection,
      };
    }
  }
  return {
    formSelectedSlot,
    timezone: pageData.timezone,
    isEcom: pageData.isEcom,
    dynamicPricePreSelection,
  };
};

export const generateNestedSlots = (
  pageData: PageAPIData,
): IncompleteFormNestedSlot[] => {
  if (pageData.slotAvailability?.nestedSlots?.length) {
    return pageData.slotAvailability?.nestedSlots.map(
      (slot): FormNestedSlot => {
        return {
          lineItemId: generateLineItemId(),
          availabilityType: AvailabilityType.GOLDEN,
          startDate: slot.start!,
          endDate: slot.end!,
          serviceId: slot.serviceId!,
          resource: slot.resource,
          sessionId: pageData.slotAvailability?.slot?.sessionId,
          scheduleId: pageData.slotAvailability?.slot?.scheduleId,
          timezone: pageData.timezone,
          location: pageData.slotAvailability?.slot?.location,
        };
      },
    );
  }

  // we always work with nested slot also for old flow or one slot to be consisted
  return [
    {
      lineItemId: generateLineItemId(),
      availabilityType: AvailabilityType.STANDARD,
      startDate: pageData.slotAvailability?.slot?.startDate!,
      endDate: pageData.slotAvailability?.slot?.endDate!,
      serviceId:
        pageData.slotAvailability?.slot?.serviceId! || pageData.serviceId,
      resource: pageData.slotAvailability?.slot?.resource,
      sessionId: pageData.slotAvailability?.slot?.sessionId,
      scheduleId: pageData.slotAvailability?.slot?.scheduleId,
      timezone: pageData.timezone,
      location: pageData.slotAvailability?.slot?.location,
    } as IncompleteFormNestedSlot,
  ];
};
