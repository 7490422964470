import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { resolveURLData } from './URLAdapter/URLDataResolver';
import { FormPageData } from '../../types/types';
import { FormState } from '../../utils/state/types';

export const getPageAPIData = async ({
  flowAPI,
  formState,
}: {
  flowAPI: ControllerFlowAPI;
  formState: Partial<FormState>;
}): Promise<FormPageData | undefined> => {
  try {
    let formPageData = formState.formPageData;
    if (!formPageData) {
      formPageData = await resolveURLData({ flowAPI });
    }

    return formPageData;
  } catch (error) {
    console.error('ERROR: failed to resolve page API data', error);
    return undefined;
  }
};
